import { Box, chakra, Flex, Text, VStack } from '@chakra-ui/react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Product } from '../../../types/Product'
import AddToCartButton from '../context/AddToCartButton'

const ProductCard: React.FC<{ product: Product }> = ({ product }) => {
  const image = getImage(product?.image?.localFile?.childImageSharp)
  const slug = `/${product.slug}`

  let price
  if (product.salePrice !== null) {
    price = (
      <>
        <del>{product.regularPrice}</del>&nbsp;&nbsp;{product.salePrice}
      </>
    )
  } else {
    price = <>{product.regularPrice}</>
  }

  return (
    <Box
      maxW='xs'
      mx='auto'
      bg={'rgba(26, 11, 11, 0.75)'}
      shadow='lg'
      rounded='lg'
      style={{
        backdropFilter: 'blur(16px) saturate(180%)',
        WebkitBackdropFilter: 'blur(16px) saturate(180%)',
      }}
      m={5}
      p={0}
    >
      <Link to={slug}>
        <Flex
          color={'white'}
          p={2}
          minH={{ base: 35, md: 50, lg: 100 }}
          alignItems='center'
          justifyContent='center'
        >
          <Text
            fontWeight='bold'
            fontSize={{ base: 'xs', md: 'sm', lg: 'lg', xl: '2xl' }}
            textTransform='uppercase'
            textAlign='center'
          >
            {product.name}
          </Text>
        </Flex>
        <Box px={4} py={2} w={{ base: 150, md: 'full' }}>
          {image ? (
            <GatsbyImage
              image={image}
              alt={product.name}
              objectFit={'scale-down'}
            />
          ) : null}
        </Box>
      </Link>

      <VStack w='full' pb={5}>
        <chakra.h1
          color='white'
          fontWeight='bold'
          fontSize={{ base: 'md', md: 'lg' }}
        >
          {price}
        </chakra.h1>
        <AddToCartButton productId={product.databaseId} quantity={1} />
      </VStack>
    </Box>
  )
}

export default ProductCard
