import React from 'react'
import { Product } from '../../../types/Product'
import {
  GridItem,
  SimpleGrid,
  VStack,
  Flex,
  Box,
  Heading,
  Spacer,
  Button,
} from '@chakra-ui/react'
import ProductCard from './ProductCard'

type products = [
  {
    node: Product
  }
]

const ProductGrid: React.FC<{ products: products; title: string }> = (
  props
) => {
  const productList = props.products
  //console.log(productList)
  const heading =
    props.title != '' ? (
      <Flex w='full'>
        <Box p='2'>
          <Heading size='lg' color='black'>
            {props.title}
          </Heading>
        </Box>
        <Spacer />
        <Box pr='15'>
          <Button colorScheme='red' mr='4'>
            View More
          </Button>
        </Box>
      </Flex>
    ) : null

  return (
    <VStack
      w='full'
      h='full'
      p={0}
      align='flex-start'
      pos='relative'
      style={{ clear: 'both' }}
    >
      {heading}
      <SimpleGrid
        columns={{ base: 2, md: 3, lg: 4 }}
        p={0}
        w='full'
        alignItems='center'
        justifyContent='center'
        className='product-card'
      >
        {productList.map((product) => (
          <GridItem colSpan={1} key={product.node.id} p={0}>
            <ProductCard product={product.node} />
          </GridItem>
        ))}
      </SimpleGrid>
    </VStack>
  )
}

export default ProductGrid
