import React from 'react'
import Layout from '../components/common/Layout'
import { Box, Image } from '@chakra-ui/react'
import banner from '../images/1.jpg'
import { graphql, PageProps } from 'gatsby'
import { allProductQuery } from '../../types/AllProductQuery'
import ProductGrid from '../components/product/ProductGrid'

const index: React.FC<ProductProps> = ({ data }) => {
  const products = data.allWpProduct.edges

  return (
    <Layout>
      <Box w='full' pos='absolute' top='0' left='0' zIndex='-1'>
        <Image src={banner} mb='5' />
        <ProductGrid products={products} title={'Our Products'} />
      </Box>
    </Layout>
  )
}

type ProductProps = PageProps<allProductQuery>

export const query = graphql`
  query ALLPRODUCTS {
    allWpProduct {
      edges {
        node {
          name
          id
          databaseId
          ... on WpSimpleProduct {
            regularPrice
            salePrice
          }
          slug
          description
          shortDescription
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 600
                  width: 600
                  webpOptions: { quality: 100 }
                  formats: WEBP
                )
              }
            }
          }
        }
      }
    }
  }
`

export default index
